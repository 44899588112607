@import "../../styles/theme.variables.scss";

.dashboard-page-header {
  display: flex;
  gap: 18px;

  @media only screen and (max-width: 480px) {
    display: block;
    gap: 0;
  }

  .tab-area {
    flex: 1;
    border-bottom: 2px #505050 solid;

    &>div {
      height: auto;
      margin: 0;
      overflow: visible;

      &>div {
        padding: 0;
        min-height: auto;
        overflow: visible;
        height: auto;
        max-height: none;

        button {
          padding: 0 0 12px;

          &::after {
            bottom: -2px;
          }
        }
      }
    }
  }

  .create-device-button {
    @media only screen and (max-width: 480px) {
      margin-top: 8px;
      width: 100%;
    }
  }

  .open-secure-connect-button {
    @media only screen and (max-width: 480px) {
      margin-top: 8px;
      width: 100%;
    }
  }
}

.tab-panel-content {
  padding: 16px 0;
}

