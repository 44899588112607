.item-selec-wrap-cls {
    margin: 12px 0;

    .item-selec-text-cls {
        margin-right: 14px;
    }
}

.assign-site-modal-table{
    max-height: 36vh;
}
.assign-site-modal-pagination{
    padding: 14px 0 0 !important;
}