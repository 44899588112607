.customize-columns {
    height: 100vh;
    max-height: 66vh !important;
    min-width: 30rem !important;

    &>div:nth-child(1) {
        padding-top: 1.5rem !important;
        padding-left: 1.5rem !important;
        padding-right: 1.5rem !important;

        h4 {
            font-size: 20px;
            text-transform: unset;
            font-weight: 700;
            letter-spacing: normal;
            line-height: 30px;
            padding: 0;
            margin: 0;
        }

        h3 {
            font-size: 14px;
            letter-spacing: normal;
            line-height: 20px;
            font-weight: 500;
        }
    }

    &>div:nth-child(2) {
        &>div {
            padding-left: 1.5rem !important;
            padding-right: 1.5rem !important;
        }
    }

    &>div:nth-child(3) {
        padding-bottom: 1.5rem !important;
        padding-left: 1.5rem !important;
        padding-right: 1.5rem !important;
    }
}
.grab-icon-disabled{
    fill: #444 !important;
}