@import "../../styles/theme.variables.scss";


.filter-modal-dialog {
  height: 100vh;
  max-height: 78vh;
  min-width: 27rem;

  h3 {
    font-size: 20px !important;
  }

  .filter-accordian {
    &>div {
      &>button {
        &:hover {
          background: none !important;
        }

        &>span {
          &:nth-child(2) {
            width: 24px;
            height: 24px;
            border-radius: 50%;
            background: #f0f0f0;
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 0;
            margin-left: 8px;
            color: #303030;
            font-size: 14px;
          }
        }
      }
    }
    &.hide-org-dropdown{
      & > div:nth-child(3) {
        display: none;
      }
    }
  }

  &>div:nth-child(1) {
    padding-top: 1.5rem !important;
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
  }

  &>div:nth-child(2) {
    &>div {
      padding-left: 0 !important;
      padding-right: 0 !important;
    }
  }

  &>div:nth-child(3) {
    padding-bottom: 1.5rem !important;
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
  }
}