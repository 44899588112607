@tailwind base;
@tailwind components;
@tailwind utilities;

@import "./font-family.scss";

@import "/node_modules/@forge/common/dist/styles/colors/_index.scss";
@import "/node_modules/@forge/common/dist/styles/base/_index.scss";
@import "./color.scss";
@import "./mixins.scss";
@import "./theme.variables.scss";

.iotdevices-container {
  padding: 14px 24px 0;

  @media only screen and (max-width: 1024px) {
    padding: 20px;
  }

  @include themify($themes) {
    background: themed("colorBackground") !important;
  }
}

body,
button {
  @include use-font-family();
}

* {
  @include use-font-family();
}

.theme-dark {
  background: $dark-bg-color !important;
}

.success {
  @include themify($themes) {
    color: themed("colorSuccess");
  }
}

.in-progress {
  @include themify($themes) {
    color: themed("colorInProgress");
  }
}

.failed {
  @include themify($themes) {
    color: themed("colorFailed");
  }
}

.status-disconnected-color-cls {
  background: var(--error-status-color) !important;
}

.status-connected-color-cls {
  background: var(--active-status-color) !important;
}

.status-registration_pending-color-cls {
  background: var(--pending-status-color) !important;
}

.status-warning-color-cls {
  background: var(--warning-status-color) !important;
}

.status-deactivated-color-cls {
  background: var(--deactivated-status-color) !important;
}

.status-certificate_expired-color-cls {
  background: var(--error-status-color) !important;
}

.font-size-14 {
  font-size: 0.875rem;
}

// For custom scroll bar design
/* width */
::-webkit-scrollbar {
  width: 8px;
  height: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 2px grey;
  border-radius: 8px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #ccc;
  border-radius: 8px;
}