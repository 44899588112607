@import "../../../styles/theme.variables.scss";

.diagnostics-file-download-container {
  height: 4.25rem;
  gap: 1rem;
  align-items: center;
  padding: 0.75rem 1rem 0.75rem 1rem;
  margin-bottom: 0.5rem;

  @include themify($themes) {
    background-color: themed("colorBgGray");
  }

  .icon-file-wrapper {
    width: 3.125rem;
    height: 3.125rem;

    @include themify($themes) {
      background-color: themed("colorBgFileIcon");
    }

    .default-icon-file {
      width: 2rem;
      height: 2rem;
    }
  }

  .progress-container {
    h5 {
      display: none;
    }
  }
}
