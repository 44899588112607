@import "../../../../styles/theme.variables.scss";

.reusable-tab-card-container {
    max-width: 104em !important;
    margin-bottom: 12px;
    margin-top: 20px;
    border-radius: 10px;

    .reusable-tab-card-title {
        font-size: 20px;
        font-weight: 700;
        line-height: 28px;

    }

    .data-card-container {
        padding: 0 1.5em !important;

        .reusable-info-container {

            .reusable-info-cell {
                display: flex;
                flex-direction: row;
                align-items: stretch;
                justify-content: flex-start;
                padding: 16px 0px;
                padding-bottom: 0px;

                .data-card-alert-circle-icon {
                    margin-right: 10px;
                    margin-left: 8px;
                }

                .reusable-info-label {
                    font-size: 16px;
                    font-weight: 600;
                    margin-bottom: 10px;
                    width: 300px;
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    justify-content: flex-start;

                    @include themify($themes) {
                        color: themed("colorBlackFontColor");
                    }
                }

                .reusable-info-value {
                    font-size: 16px;
                    font-weight: 500;
                    width: 100%;
                    color: #868686;

                    @include themify($themes) {
                        color: themed("grayBlackFontColor");
                    }
                }
            }
        }
    }

    .card-header-container {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .card-header-title h3 {
            font-size: 20px;
            font-weight: 700;
            line-height: 28px;
            text-transform: capitalize;
        }

        .data-card-button {
            font-size: 14px;
            border-radius: 4px;
            padding: 6px 24px;
            color: #0071B3;
            background-color: #fff;
            margin-right: 18px;
            width: 142px;
        }
    }
}

.overviewtable-skip {
    box-shadow: none !important;
    border: none !important;

    ._4ek4cf {
        padding: 0 !important;
        padding-bottom: 5px !important;
    }
}

.overviewtable-skip-header {
    box-shadow: none !important;
    border: none !important;
    padding: 0 !important;
    padding-bottom: 5px !important;
}