.customize-table-wrap {
    height: 60px;
    padding: 14px;
    position: relative;
    border-radius: 4px;
    background: var(--header-background-color);

    .table-custom-title-cls {
        left: 14px;
        font-size: 16px;
        font-weight: 600;
        position: absolute;
        color: var(--normal-text-color);
    }
}