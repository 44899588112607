.upper-dashboard-page-header {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    background-color: var(--header-background-color);
    width: 100%;
    padding-bottom: 16px;
    padding-top: 16px;
    padding-left: 24px;
    padding-right: 24px;
    margin-bottom: 16px;
    .title-area{
      .title-text{
          font-weight: 800;
          font-size: 24px;
          line-height: 32px;
          color: var(--header-title-color);
      }
    }
    .subtitle-area{
      .sub-title-text{
        font-weight: 500;
          font-size: 14px;
          line-height: 20px;
          color: var(--header-subtitle-color);  
      }
    }
  }