.bulk-assignment{
    .bulk-assignment-back-button{
        padding: 0 24px !important;
        span{
            left: 0 !important;
        }
    }
    .bulk-assignment-title{
        font-size: 28px;
        line-height: 36px;
        font-weight: 700;
    }
    .bulk-assignment-site-table{
        .overviewtable-skip{
            .customize-table-wrap + div{
                max-height: 35vh;                
            }
        }
    }
}
.bulk-assignment-confirm-button{
    color: #fff !important; 
}