.publish-frequency-container {
    display: flex;
    justify-content: space-around;
    margin-top: 20px;
    margin-bottom: 20px;
    align-items: flex-end;
    width: 36rem;
    flex-direction: row;

    #frequency-combobox {
        height: 40px !important;
    }

    .frequency-input-box {
        height: 40px !important;
    }
}