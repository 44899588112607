.no-device-card{
    width: 100%;
    height: 80vh;
    box-shadow: none;
    border: none;
    .no-device-card-header{
        padding: 16px 24px;
        border-bottom: 2px solid var(--border-light-color);
        & > div{
            h3{
                line-height: 30px !important;
                font-size: 20px !important;
                text-transform: capitalize !important;
                letter-spacing: 0 !important;
                font-weight: 700 !important;
            }
            @media only screen and (max-width: 1024px){
                h3{
                    font-size: 16px !important;
                    line-height: 24px !important;
                }
            }
        }
    }
    .no-device-content{
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        h2{
            font-size: 32px;
            line-height: 40px;
            font-weight: 500;
            letter-spacing: 0;
            color: var(--typography-light-tertiary);
            @media only screen and (max-width: 1024px){
                font-size: 24px;
                line-height: 32px;
            }
            @media only screen and (max-width: 480px){
                font-size: 18px;
                line-height: 24px;
            }
        }
    }
}