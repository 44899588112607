.table-header-right-action{
    .show-hide-icon{
        transform: rotate(-90deg);        
    }
}
.guid-col{
    width: 15ch;
    display: block;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.status-error-color-cls {
    background: var(--error-status-color) !important;
}

.status-active-color-cls {
    background: var(--active-status-color) !important;
}

.status-pending-color-cls {
    background: var(--pending-status-color) !important;
}

.status-warning-color-cls {
    background: var(--warning-status-color) !important;
}

.status-deactivated-color-cls {
    background: var(--deactivated-status-color) !important;
}

.status-certificate_expired-color-cls {
    background: var(--error-status-color) !important;
}

.scrollable-container {
    overflow-y: auto;
  }