.toast-title {
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    word-wrap: break-word;
}

.toast-subtitle {
    margin-top: 8px;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    text-overflow: ellipsis;
    max-width: 325px;
}