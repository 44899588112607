@import '../../styles/theme.variables.scss';

.create-device-container {
    margin-top: 5px;
  }
  
  .create-device-title {
    font-weight: bold;
    font-size: 1.2rem;
    margin-top: 10px;
  }
  
  .create-device-subtitle {
    font-weight: bold;
    font-size: 0.9rem;
    display: block;
  }
  
  .basic-info-text {
    font-weight: 500;
  }
  
  .button-container {
    display: flex;
    justify-content: flex-end;
    margin-top: 10px;
    gap: 10px;
  }
  
  .helper-text {
    @include themify($themes) {
      color: themed("helperText");
    }
  }

  .create-device-wrap {
    .error-input-text {
      border: solid $color-failed-dark-theme 1px !important;
    }

    .error-input-text:hover {
      border: solid $color-failed-dark-theme 1px !important;
    }

    .error-input-text:active {
      border: solid $color-failed-dark-theme 1px !important;
    }

    .error-input-text:focus {
      border: solid $color-failed-dark-theme 1px !important;
    }

  }

  .re-registration-button-Container {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    margin-top: 12%;
    margin-right: 1%;
  }