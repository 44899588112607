:root{
    --color-primary: #0071b3;
    --border-color: #e0e0e0;
    --border-color-light: #666;
    --border-light-color: #f7f7f7;
    --typography-light-tertiary: #707070;
    --color-font-base: rgba(0, 0, 0, 1);
    --error-text-color: rgba(238, 49, 36, 1);
    --normal-text-color: rgba(48, 48, 48, 1);
    --active-text-color: rgba(96, 96, 96, 1);
    --green-font-color: rgba(0, 112, 0, 1);
    --gray-shade-font-color: rgba(134, 134, 134, 1);
    --error-status-color: #EE3124;
    --active-status-color: #159D62;
    --pending-status-color: #1792E5;
    --warning-status-color: #D1670B;
    --deactivated-status-color: #606060;
    --filter-modal-header-color: #303030;
    --filter-border-bottom: 1px solid #F7F7F7;
    --link-name-color:#64C3FF;
    --hyperlink-name-color: #0071b3;
    --header-background-color: #ffffff;
    --header-title-color: #303030;
    --header-subtitle-color: #333333;
}

html.dark{
    --color-primary: #0071b3;
    --border-color: rgb(80,80,80);
    --border-color-light: #f0f0f0;
    --border-light-color: rgb(80,80,80);
    --typography-light-tertiary: #ccc;
    --color-font-base: rgb(252, 249, 249);
    --error-text-color: rgba(238, 49, 36, 1);
    --normal-text-color: rgb(252, 249, 249);
    --active-text-color: rgb(191 186 186);
    --green-font-color: rgba(0, 112, 0, 1);
    --gray-shade-font-color: rgb(250 246 246);
    --error-status-color: #EE3124;
    --active-status-color: #159D62;
    --pending-status-color: #1792E5;
    --warning-status-color: #D1670B;
    --deactivated-status-color: #606060;
    --filter-modal-header-color: #f0f0f0;
    --filter-border-bottom: 1px solid #505050;
    --link-name-color:#64C3FF;
    --hyperlink-name-color: #0071b3;
    --header-background-color: #303030;
    --header-title-color: #F0F0F0;
    --header-subtitle-color: #d0d0d0;
}