@font-face {
    font-family: "Honeywell Sans Web";
    font-style: normal;
    font-weight: 300;
    font-display: swap;
    src: url(../../node_modules/@forge/common/dist/assets/fonts/HoneywellSansWeb-Book.woff2) format("woff2"),
        url(../../node_modules/@forge/common/dist/assets/fonts/HoneywellSansWeb-Book.woff) format("woff");
}

@font-face {
    font-family: "Honeywell Sans Web";
    font-style: italic;
    font-weight: 300;
    font-display: swap;
    src: url(../../node_modules/@forge/common/dist/assets/fonts/HoneywellSansWeb-BookItalic.woff2) format("woff2"),
        url(../../node_modules/@forge/common/dist/assets/fonts/HoneywellSansWeb-BookItalic.woff) format("woff");
}

@font-face {
    font-family: "Honeywell Sans Web";
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url(../../node_modules/@forge/common/dist/assets/fonts/HoneywellSansWeb-Medium.woff2) format("woff2"),
        url(../../node_modules/@forge/common/dist/assets/fonts/HoneywellSansWeb-Medium.woff) format("woff");
}

@font-face {
    font-family: "Honeywell Sans Web";
    font-style: italic;
    font-weight: 400;
    font-display: swap;
    src: url(../../node_modules/@forge/common/dist/assets/fonts/HoneywellSansWeb-MediumItalic.woff2) format("woff2"),
        url(../../node_modules/@forge/common/dist/assets/fonts/HoneywellSansWeb-MediumItalic.woff) format("woff");
}

@font-face {
    font-family: "Honeywell Sans Web";
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: url(../../node_modules/@forge/common/dist/assets/fonts/HoneywellSansWeb-Bold.woff2) format("woff2"),
        url(../../node_modules/@forge/common/dist/assets/fonts/HoneywellSansWeb-Bold.woff) format("woff");
}

@font-face {
    font-family: "Honeywell Sans Web";
    font-style: italic;
    font-weight: 700;
    font-display: swap;
    src: url(../../node_modules/@forge/common/dist/assets/fonts/HoneywellSansWeb-BoldItalic.woff2) format("woff2"),
        url(../../node_modules/@forge/common/dist/assets/fonts/HoneywellSansWeb-BoldItalic.woff) format("woff");
}

@font-face {
    font-family: "Honeywell Sans Web";
    font-style: normal;
    font-weight: 800;
    font-display: swap;
    src: url(../../node_modules/@forge/common/dist/assets/fonts/HoneywellSansWeb-Extrabold.woff2) format("woff2"),
        url(../../node_modules/@forge/common/dist/assets/fonts/HoneywellSansWeb-Extrabold.woff) format("woff");
}

@font-face {
    font-family: "Honeywell Sans Web";
    font-style: italic;
    font-weight: 800;
    font-display: swap;
    src: url(../../node_modules/@forge/common/dist/assets/fonts/HoneywellSansWeb-ExtraboldItalic.woff2) format("woff2"),
        url(../../node_modules/@forge/common/dist/assets/fonts/HoneywellSansWeb-Extrabold.woff) format("woff");
}

@font-face {
    font-family: "Honeywell Sans Web";
    font-style: normal;
    font-weight: 900;
    font-display: swap;
    src: url(~../../node_modules/@forge/common/dist/assets/fonts/HoneywellSansWeb-Black.woff2) format("woff2"),
        url(../../node_modules/@forge/common/dist/assets/fonts/HoneywellSansWeb-Black.woff) format("woff");
}

@font-face {
    font-family: "Honeywell Sans Web";
    font-style: italic;
    font-weight: 900;
    font-display: swap;
    src: url(../../node_modules/@forge/common/dist/assets/fonts/HoneywellSansWeb-BlackItalic.woff2) format("woff2"),
        url(../../node_modules/@forge/common/dist/assets/fonts/HoneywellSansWeb-BlackItalic.woff) format("woff");
}
