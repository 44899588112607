@import "./variables.scss";

// Define themes
$themes: (
  light: (
    borderColor: $white-color,
    colorText: $app-dark-bg,
    colorBackground: $app-light-bg,
    normalText: $app-light-mode-text,
    activeText: $mild-grey-color,
    errorStatus: $error-status-color,
    activeStatus: $active-status-color,
    pendingStatus: $pending-status-color,
    warningStatus: $warning-status-color,
    deactivatedStatus: $deactivated-status-color,
    grayShadeFontColor: $gray-shade-font-color,
    colorSuccess: $color-success-light,
    colorInProgress: $color-in-progress-light-theme,
    colorBgGray: $color-bg-gray-light-theme,
    colorBgFileIcon: $color-bg-file-icon-light-theme,
    grayBlackFontColor: $gray-shade-font-color,
    colorBlackFontColor: $app-dark-bg,
    borderBottomColor: $light-grey-color,
    colorFailed: $color-failed-light-theme,
    helperText: $helper-text-color,
  ),
  dark: (
    borderColor: $white-color,
    colorText: $app-light-bg,
    colorBackground: $app-bg-black,
    normalText: $light-whitesh-color,
    activeText: $light-mild-grey-color,
    errorStatus: $error-status-color,
    activeStatus: $active-status-color,
    pendingStatus: $pending-status-color,
    warningStatus: $warning-status-color,
    deactivatedStatus: $deactivated-status-color,
    grayShadeFontColor: $light-gray-shade-font-color,
    colorSuccess: $color-success-dark,
    colorInProgress: $color-in-progress-dark-theme,
    colorBgGray: $color-bg-gray-dark-theme,
    colorBgFileIcon: $color-bg-file-icon-dark-theme,
    grayBlackFontColor: $mild-grey,
    colorBlackFontColor: $app-dark-mode-filter-header-color,
    borderBottomColor: $grey-color,
    colorFailed: $color-failed-dark-theme,
    helperText: $helper-text-color,
  ),
);

// Mixin to apply theming
@mixin themify($themes) {
  @each $theme, $map in $themes {
    .theme-#{$theme} & {
      // Reset the theme map before applying the new values
      $theme-map: () !global;

      // Loop through the theme map and populate $theme-map
      @each $key, $value in $map {
        $theme-map: map-merge(
          $theme-map,
          (
            $key: $value,
          )
        ) !global;
      }

      // Apply styles within the theme
      @content;

      // Clear the theme map after use
      $theme-map: null !global;
    }
  }
}

// Function to get themed value
@function themed($key) {
  @return map-get($theme-map, $key);
}

// Usage Example
.iotdevices-container {
  @include themify($themes) {
    background: themed("colorBackground") !important;
    color: themed("colorText") !important;
  }
}
