@import '../../styles/theme.variables.scss';

.view-device-overview-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    .view-device-left-container {
        width: 100%;

        .view-device-details-header {
            .view-device-details-title {
                font-size: 24px;
                font-weight: 800;
                @include themify($themes) {
                    color: themed("colorBlackFontColor");
                }
                margin: 3px 0px;
            }

            .view-device-name-container {
                display: flex;
                flex-direction: row;
                justify-content: flex-start;
                align-items: baseline;

                .view-device-name {
                    font-size: 16px;
                    font-weight: 800;
                    @include themify($themes) {
                        color: themed("colorBlackFontColor");
                    }
                }

                .view-device-details-status {
                    margin-left: 10px;
                    display: flex;
                    flex-direction: row;
                    align-items: baseline;

                    .view-device-status-text {
                        font-size: 14px;
                        font-weight: 500;
                        @include themify($themes) {
                            color: themed("colorBlackFontColor");
                        }
                    }
                    .view-dev-status-badge-cont-cls{
                        margin-left: 5px;
                    }
                }
            }
        }
    }

    .view-device-right-container {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        align-items: center;
        gap: 16px;

    }

}
.view-device-notification-banner {
    margin-top: 20px;
    margin-bottom: 12px;
}