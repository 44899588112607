.confirmation-modal-footer {
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;
    gap: 16px;
    max-height: unset !important;
  }

.confirmation-modal {
    max-width: 100%;
    width: 550px;
    max-height: 80%;
}

.confirmation-modal-content {
    color: var(--normal-text-color) !important;
    font-size: 16px;
    font-weight: 500;
}