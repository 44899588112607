.modal-footer {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
  gap: 16px;
}

.toast-container {
  position: fixed;
  top: 20px;
  right: 20px;
  z-index: 999999999;
  width: auto;
  max-width: 90%;
}

.toast-content {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
}

.toast-subtitle {
  font-weight: 500;
  font-size: 14px;
}

.action-modal {
  width: 440px;
}

.modal-content {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
}

.note {
  display: flex;
  margin-top: 20px;
  max-width: 500px;

  .important-text{
    margin-left: 6px; 
    margin-bottom: 10px;
  }

  .info-icon {
    color: #64C3FF;
    margin-right: 6px;
  }
}