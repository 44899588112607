@import '../../styles/variables.scss';
@import '../../styles/theme.variables.scss';

.edit-device-title-cls {
    font-size: 24px;
    font-weight: 800;
    padding: 0 !important;
    @include themify($themes) {
        color: themed("colorText") !important;
    }
}

.iot-bread-crumb-cls {
    margin: 0px 0px 12px 0px;

    button {
        font-size: 14px !important;
        font-weight: 500 !important;

        @include themify($themes) {
            color: themed("normalText") !important;
        }

        &:disabled {
            @include themify($themes) {
                color: themed("colorText") !important;
            }
        }
    }
}

.iot-name-block-cls {
    display: flex;
    margin: 12px 0px;

    .iot-device-name-txt-cls {
        font-size: 16px;
        font-weight: 800;
        max-width: 450px;
        word-break: break-all;
        @include themify($themes) {
            color: themed("normalText") !important;
        }
    }
}

.iot-error-txt-cls {
    font-weight: 800 !important;
    @include themify($themes) {
        color: themed("errorStatus") !important;
    }
}

.iot-basic-info-text {
    font-size: 20px;
    font-weight: 700;
    @include themify($themes) {
        color: themed("normalText") !important;
    }
}

.iot-label-name-txt-cls {
    font-size: 16px !important;
    font-weight: 700 !important;
    @include themify($themes) {
        color: themed("normalText") !important;
    }
}

.iot-device-type-block-cls {
    display: flex;
    padding: 10px 0px 10px 0px;
    border-top: 2px solid !important;
    @include themify($themes) {
        color: themed("borderBottomColor") !important;
    }

    .iot-device-type-txt-cls {
        width: 171px;
        font-size: 16px;
        font-weight: 700;
        @include themify($themes) {
            color: themed("normalText") !important;
        }

        .iot-info-icon-cls {
            margin-left: 5px;
            cursor: pointer;
        }
    }

    .iot-device-type-val-cls {
        font-size: 16px;
        font-weight: 500;
        @include themify($themes) {
            color: themed("grayShadeFontColor") !important;
        }
    }
}

.edit-device-buttons-block-cls {
    margin-top: 27px;
    text-align: right;

    .cancel-btn-cls {
        margin-right: 15px;
    }
}

.edit-dev-card-block-cls {
    padding: 1.25rem !important;    
}

.edit-dev-status-badge-cont-cls {
    margin-left: 15px;
}

ul#site-combo-id-dropdown {
    max-height: 120px !important;
}

ul#org-combo-id-dropdown {
    max-height: 160px !important;
}

// #site-combo-id {
//     height: 120px !important;
// }

.retry-text-link-cls {
    cursor: pointer;
    color: var(--link-name-color);

    &:hover {
        text-decoration: underline;
    }
}

.error-wrap-text-cls {
    font-weight: 600;
}