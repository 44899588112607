.table-header-right-action {
    .show-hide-icon {
        transform: rotate(-90deg);
    }
}

.iot-error-status-cls {
    svg {
        margin-left: 5px;
    }
}

.guid-col {
    max-width: 20ch;
    width: auto;
    display: inline-block;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.device-name-link {
    color: var(--link-name-color) !important;
    font-size: 14px;
    font-weight: 500;
    text-decoration: none;
    cursor: pointer;
    padding: 0px 0 !important;
    margin: 0px 0px !important;
    height: 0px !important;

    &:hover::after {
        background-color: transparent !important;
    }

    &:active {
        background-color: transparent !important;
    }
}

.gateway-action-button-container {
    display: flex;
    width: 100%;
    justify-content: flex-start;
    align-items: baseline;
    flex-direction: row;
    margin: 0 0;
    padding: 0px 0;
    gap: 8px;

    button {
        height: 12px !important;
        width: 12px !important;
        padding: 9px 12px !important;
    }
}

.custom_table_design {
    @media (max-height: 1920px) {
        height: calc(100vh - 350px);
    }

    @media (max-height: 768px) {
        height: calc(100vh - 350px);
    }

    @media (max-height: 700px) {
        height: calc(100vh - 350px);
    }

    table {
        thead {
            tr {
                th {
                    @media only screen and (max-width: 1700px) {
                        width: calc((100% - 150px) / 6) !important;

                        &:last-child {
                            width: 150px !important;
                        }
                    }
                }
            }
        }

        tbody {
            td {
                @media only screen and (max-width: 1700px) {
                    width: calc((100% - 150px) / 6) !important;

                    &:last-child {
                        width: 150px !important;
                    }
                }
            }
        }
    }
}

.disabled-edit-icon-cls {
    cursor: not-allowed;
}