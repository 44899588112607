.delete-modal-footer {
    display: flex;
    justify-content: flex-end;
    gap: 16px;
}

.delete-modal {
    width: 560px;
}

.confirm-text {
    margin-top: 20px;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
}

.confirm-input {
    margin-top: 4px;
}

.toast-container {
    position: fixed;
    top: 20px;
    right: 20px;
    z-index: 999999999;
    width: auto;
    max-width: 90%;
}

.toast-content {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
}